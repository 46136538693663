import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../Theme/themeContext";
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints";
import { whitelabel_id } from "../../../constants/ids";
import apiCall from "../../../utils/apiCall";

const Footer = ({ title = "" }) => {
    const [configSettings, setConfigSettings] = useState('')
    const { lightTheme, toggleTheme } = useTheme();
    // const [logo, setLogo] = useState('')
    useEffect(() => {

        const scriptUrls = [
            "js/custom.min.js",
            "js/dlabnav-init.js",

        ];

        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        // Load each script asynchronously

        return () => {
            // Clean up: Remove the script elements when the component unmounts
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);
    useEffect(() => {
        const config = JSON.parse(sessionStorage.getItem('configSettings'))
        setConfigSettings(config)
        // getConfigSettings()
    }, [])
    const getConfigSettings = async () => {

        const url = API_ENDPOINTS.GET_CONFIG_SETTINGS
        const body = {
            whitelabel_id: whitelabel_id,
        }
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.status == 1) {
            const data = response?.config

            // setLogo(data?.dark_logo)




        } else if (response?.status == 0) {
            console.error(response?.message)


        } else {
            console.error('Unable to fetch.')
        }
    }
    return (
        <div className=" pt-5" style={{ minWidth: title == 'pricing' ? '500px' : title == 'home' ? '600px' : title == 'contact' ? '300px' : title == 'tc' ? '300px' : title == 'home2' ? '' : '500px', backgroundColor: '#141774', marginTop: '10%' }} >
            <div className="row w-75 mx-auto" >
                <div className="col-lg-3 col-md-6 col-12" >
                    <img src={configSettings?.dark_logo || ""}
                        style={{ width: '170px' }}
                        alt="logo" />
                </div>
                <div className="col-lg-5  d-none d-lg-flex flex-wrap justify-content-between align-items-center" style={{ lineHeight: '1' }}>
                    <h4 className="m-0 "> <a href="https://codegotech.com/policy/amlpolicy.pdf" rel="noopener noreferrer" className={lightTheme ? "footerWhiteText" : ''} >AML POLICY</a></h4>
                    <h4 className="m-0"> <a href="https://codegotech.com/policy/antifraud.pdf" rel="noopener noreferrer" className={lightTheme ? "footerWhiteText" : ''}>ANTI FRAUD POLICY</a></h4>
                    <h4 className="m-0"> <a href="https://codegotech.com/policy/risk.pdf" rel="noopener noreferrer" className={lightTheme ? "footerWhiteText" : ''}>RISK MANAGEMENT</a></h4>
                    <h4 className="m-0"><a href="https://codegotech.com/policy/anti.pdf" rel="noopener noreferrer" className={lightTheme ? "footerWhiteText" : ''}>ANTI-BRIBERY POLICY</a></h4>
                    <h4 className={`${lightTheme ? "footerWhiteText" : 'footerWhiteText'} m-0`} >CODEGO EVENTS</h4>
                    <h4 className="m-0"><a href="https://cointelegraph.com/news/crypto-cards-to-connect-digital-assets-to-the-real-world-says-exec" rel="noopener noreferrer" className={lightTheme ? "footerWhiteText" : ''}>NEWS</a></h4>
                    <h4 className="m-0 "><Link to={"/privacy-policy"} className={lightTheme ? "footerWhiteText" : ''}>PRIVACY POLICY</Link></h4>
                    <h4 className="m-0"><Link to={"/terms-conditions"} className={lightTheme ? "footerWhiteText" : ''}>TERMS CONDITIONS</Link></h4>
                    <h4 className="m-0"><Link to={"/contact"} className={lightTheme ? "footerWhiteText" : ''}>CONTACT</Link></h4>
                    {/* <h4 className="m-0">ABOUT</h4> */}
                </div>

                <div className="col-lg-4 col-md-6 text-end d-flex justify-content-center gap-2 mx-sm-auto footerWhiteText2" >
                    <a href={configSettings?.facebooklink} rel="noopener noreferrer" > <i class="fab fa-facebook-f rounded-circle bg-primary p-2 d-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }}></i></a>
                  <a href={configSettings?.linkdin} rel="noopener noreferrer" >  <i class="fab fa-linkedin rounded-circle bg-primary p-2 d-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }}></i></a>
                  <a href={configSettings?.instagram} rel="noopener noreferrer" > <i class="fab fa-instagram rounded-circle bg-primary p-2 d-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }}></i></a>
                  <a href={configSettings?.twitterlink} rel="noopener noreferrer" >  <i class="fab fa-twitter rounded-circle bg-primary p-2 d-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }}></i></a>
                </div>
            </div>
            <div className="row w-75 mx-auto">
                <div className="mt-5 pb-3">

                    <span className={lightTheme ? "footerWhiteText2" : 'footerWhiteText2'} style={{ fontSize: '18px' }}>
                        {/* © 2023 Codego . All rights reserved. */}
                        {configSettings?.copy_right_text}
                    </span>
                </div>
                <div className={`text-white pb-5  ${lightTheme ? "footerWhiteText2" : ''}`}>
                    <span className={lightTheme ? "footerWhiteText2" : ''}>

                        ©2024 Banqa. is a brand of Codego SRL.<br/>
						Paynovate is authorized by the Bank of Belgium (NBB) under the Law on Electronic Money and Electronic Money Institutions (ref 0506763929) for the issuing of electronic money and provision of the related payment services. CODEGO SRL is an Agent of Paynovate and registered in Italy under number : PAYNITA000001
                    </span>

                </div>
            </div>
        </div>
    )
}
export default Footer;
